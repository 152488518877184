import classNames from "classnames"
import Image from "next/image"
import { useState } from "react"
import { setCookie, getCookie, getCookies } from 'cookies-next'
import { useRouter } from "next/router"

export default function Cookies () {
  const [showFull, setShowFull] = useState(false)
  const [rejected, setRejected] = useState(false)
  const router = useRouter()

  const toggleShow = () => {
    setShowFull(!showFull)
  }

  const setCookiesAccept = () => {
    setCookie("cookiesAccepted", "true", {})
    router.push("/")
  }

  const setCookiesReject = () => {
    setRejected(true)
  }

  const revertReject = () => {
    setRejected(false)
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="relative z-10 flex flex-col items-stretch justify-between max-w-[600px] max-h-[70vh] px-6 pt-10 pb-6 rounded-md shadow-my-xl">
        <Image src="/iconLogo.svg" alt="Skippr logo" width={64} height={64} className="absolute left-1/2 translate-x-[-50%]" style={{ bottom: "calc(100% - 32px)" }} />
        <div className="absolute z-[-1] top-0 left-0 right-0 bottom-0 overflow-hidden rounded-md">
          <span className="absolute opacity-10 top-[-155px] left-[-40px]" style={{ fontSize: "260px" }}>🍪</span>
        </div>

        {rejected && <>
          <h1 className="text-3xl font-bold mb-6 uppercase text-center">You&apos;ve rejected cookies</h1>
          <p className="mb-2 text-gray-dark">We&apos;re currently working on cookie-less website for Skippr. In order to log in and use the rest of the functionality, we need you to <span className="underline hover:text-accent cursor-pointer" onClick={revertReject}>accept cookies.</span></p>
        </>}

        {!rejected && <><h1 className="text-3xl font-bold mb-6 uppercase text-center">Can we store cookies?</h1>
          <p className="mb-2 text-gray-dark">First things first, let&apos;s chat about cookies - not the yummy, chocolate-chip kind, but the digital ones. By clicking &quot;Accept,&quot; you&apos;re giving us the green light to use cookies to improve your experience. If you&apos;re feeling a bit rebellious and want to tweak your cookie settings, you can do so in our Privacy Preferences.</p>
          <p onClick={toggleShow} className="text-gray-dark underline hover:text-accent cursor-pointer">
            {showFull ? <span>Hide the cookie policy</span> : <span>Tell me more about those cookies</span>}
          </p>

          <div className={classNames("overflow-auto mt-2 bg-gray-light px-4", { "py-2": showFull })} style={{ maxHeight: showFull ? '300px' : 0, transition: 'max-height .3s ease-out' }}>
            <CookiesInner />
          </div>
          <div className="mt-4 space-x-2 text-right">
            <button onClick={setCookiesAccept} className="rounded-md bg-accent font-medium text-center min-w-[125px] text-white px-4 py-2 active hover:opacity-75">Accept</button>
            <button onClick={setCookiesReject} className="rounded-md bg-black font-medium text-center min-w-[125px] text-white px-4 py-2 active  hover:opacity-75">Reject All</button>
          </div></>}
      </div>
    </div>
  )
}

function CookiesInner () {

  return (
    <>
      <div>
        <strong>
          <span style={{ fontSize: 26 }}>
            <span data-custom-class="title">COOKIE POLICY</span>
          </span>
        </strong>
      </div>
      <div>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <strong>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="subtitle">
                Last updated <span className="question">September 20, 2023</span>
              </span>
            </span>
          </strong>
        </span>
      </div>
      <div>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              This Cookie Policy explains how{" "}
              <span className="question">Skippr Ltd.</span> (&quot;<strong>Company</strong>
              ,&quot; &quot;<strong>we</strong>,&quot; &quot;<strong>us</strong>,&quot; and &quot;
              <strong>our</strong>&quot;) uses cookies and similar technologies to
              recognize you when you visit our website at &nbsp;
            </span>
          </span>
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              <span className="question">
                <a
                  href="https://beta.skippr.app"
                  target="_blank"
                  rel="noreferrer"
                  data-custom-class="link"
                >
                  https://beta.skippr.app
                </a>
              </span>
            </span>
          </span>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              (&quot;<strong>Website</strong>&quot;). It explains what these technologies are
              and why we use them, as well as your rights to control our use of
              them.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              In some cases we may use cookies to collect personal information, or
              that becomes personal information if we combine it with other
              information.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
            <strong>
              <span data-custom-class="heading_1">What are cookies?</span>
            </strong>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              Cookies are small data files that are placed on your computer or
              mobile device when you visit a website. Cookies are widely used by
              website owners in order to make their websites work, or to work more
              efficiently, as well as to provide reporting information.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              Cookies set by the website owner (in this case,{" "}
              <span className="question">Skippr Ltd.</span>) are called &quot;first-party
              cookies.&quot; Cookies set by parties other than the website owner are
              called &quot;third-party cookies.&quot; Third-party cookies enable third-party
              features or functionality to be provided on or through the website
              (e.g., advertising, interactive content, and analytics). The parties
              that set these third-party cookies can recognize your computer both
              when it visits the website in question and also when it visits certain
              other websites.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
            <strong>
              <span data-custom-class="heading_1">Why do we use cookies?</span>
            </strong>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              We use first-
              <span className="block-component" /> and third-
              <span className="statement-end-if-in-editor" />
              party cookies for several reasons. Some cookies are required for
              technical reasons in order for our Website to operate, and we refer to
              these as &quot;essential&quot; or &quot;strictly necessary&quot; cookies. Other cookies
              also enable us to track and target the interests of our users to
              enhance the experience on our Online Properties.{" "}
              <span className="block-component"></span>Third parties serve cookies
              through our Website for advertising, analytics, and other purposes.{" "}
              <span className="statement-end-if-in-editor" />
              This is described in more detail below.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      How can I control cookies?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              You have the right to decide whether to accept or reject cookies. You
              can exercise your cookie rights by setting your preferences in the
              Cookie Consent Manager. The Cookie Consent Manager allows you to
              select which categories of cookies you accept or reject. Essential
              cookies cannot be rejected as they are strictly necessary to provide
              you with services.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              The Cookie Consent Manager can be found in the notification banner and
              on our website. If you choose to reject cookies, you may still use our
              website though your access to some functionality and areas of our
              website may be restricted. You may also set or amend your web browser
              controls to accept or refuse cookies.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              The specific types of first- and third-party cookies served through
              our Website and the purposes they perform are described in the table
              below (please note that the specific&nbsp;
            </span>
            <span data-custom-class="body_text">
              cookies served may vary depending on the specific Online Properties
              you visit):
            </span>
          </span>
        </span>
        <span style={{ fontSize: 15 }} />
        <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ fontSize: 15 }}>
            <strong>
              <u>
                <br />
                <br />
                Essential website cookies:
              </u>
            </strong>
          </span>
        </span>
      </div>
      <div>
        <div>
          <br />
        </div>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                These cookies are strictly necessary to provide you with services
                available through our Website and to use some of its features, such
                as access to secure areas.
              </span>
            </span>
          </span>
        </div>
        <p style={{ fontSize: 15, lineHeight: "1.5" }}>
          <span data-custom-class="body_text" />
        </p>
        <section
          data-custom-class="body_text"
          style={{
            width: "100%",
            border: "1px solid rgb(230, 230, 230)",
            margin: "0px 0px 10px",
            borderRadius: 3,
            textAlign: "left"
          }}
        >
          <div style={{ padding: "8px 13px", borderBottom: "1px solid #e6e6e6" }}>
            <table>
              <tbody>
                <tr
                  style={{
                    fontFamily: "Roboto, Arial",
                    fontSize: 12,
                    lineHeight: "1.67",
                    margin: "0 0 8px",
                    verticalAlign: "top"
                  }}
                >
                  <td
                    style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}
                  >
                    Name:
                  </td>
                  <td style={{ display: "inline-block", marginLeft: 5 }}>
                    <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                      Priority
                    </span>
                  </td>
                </tr>
                <tr
                  style={{
                    fontFamily: "Roboto, Arial",
                    fontSize: 12,
                    lineHeight: "1.67",
                    margin: 0,
                    verticalAlign: "top"
                  }}
                >
                  <td
                    style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}
                  >
                    Purpose:
                  </td>
                  <td style={{ display: "inline-block", marginLeft: 5 }}>
                    <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                      Used to protect forms from spam.
                    </span>
                  </td>
                </tr>
                <tr
                  style={{
                    fontFamily: "Roboto, Arial",
                    fontSize: 12,
                    lineHeight: "1.67",
                    margin: "0 0 8px",
                    verticalAlign: "top"
                  }}
                >
                  <td
                    style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}
                  >
                    Provider:
                  </td>
                  <td style={{ display: "inline-block", marginLeft: 5 }}>
                    <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                      c.bing.com
                    </span>
                  </td>
                </tr>
                <tr
                  style={{
                    fontFamily: "Roboto, Arial",
                    fontSize: 12,
                    lineHeight: "1.67",
                    margin: "0 0 8px",
                    verticalAlign: "top"
                  }}
                >
                  <td
                    style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}
                  >
                    Service:
                  </td>
                  <td style={{ display: "inline-block", marginLeft: 5 }}>
                    <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                      www.google.com{" "}
                    </span>
                  </td>
                </tr>
                <tr
                  style={{
                    fontFamily: "Roboto, Arial",
                    fontSize: 12,
                    lineHeight: "1.67",
                    margin: "0 0 8px",
                    verticalAlign: "top"
                  }}
                >
                  <td
                    style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}
                  >
                    Country:
                  </td>
                  <td style={{ display: "inline-block", marginLeft: 5 }}>
                    <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                      United States
                    </span>
                  </td>
                </tr>
                <tr
                  style={{
                    fontFamily: "Roboto, Arial",
                    fontSize: 12,
                    lineHeight: "1.67",
                    margin: "0 0 8px",
                    verticalAlign: "top"
                  }}
                >
                  <td
                    style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}
                  >
                    Type:
                  </td>
                  <td style={{ display: "inline-block", marginLeft: 5 }}>
                    <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                      server_cookie
                    </span>
                  </td>
                </tr>
                <tr
                  style={{
                    fontFamily: "Roboto, Arial",
                    fontSize: 12,
                    lineHeight: "1.67",
                    margin: "0 0 8px",
                    verticalAlign: "top"
                  }}
                >
                  <td
                    style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}
                  >
                    Expires in:
                  </td>
                  <td style={{ display: "inline-block", marginLeft: 5 }}>
                    <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                      session
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <p />
        <section
          data-custom-class="body_text"
          style={{
            width: "100%",
            border: "1px solid rgb(230, 230, 230)",
            margin: "0px 0px 10px",
            borderRadius: 3,
            textAlign: "left"
          }}
        >
          <div style={{ padding: "8px 13px", borderBottom: "1px solid #e6e6e6" }}>
            <table>
              <tbody>
                <tr
                  style={{
                    fontFamily: "Roboto, Arial",
                    fontSize: 12,
                    lineHeight: "1.67",
                    margin: "0 0 8px",
                    verticalAlign: "top"
                  }}
                >
                  <td
                    style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}
                  >
                    Name:
                  </td>
                  <td style={{ display: "inline-block", marginLeft: 5 }}>
                    <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                      Priority
                    </span>
                  </td>
                </tr>
                <tr
                  style={{
                    fontFamily: "Roboto, Arial",
                    fontSize: 12,
                    lineHeight: "1.67",
                    margin: 0,
                    verticalAlign: "top"
                  }}
                >
                  <td
                    style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}
                  >
                    Purpose:
                  </td>
                  <td style={{ display: "inline-block", marginLeft: 5 }}>
                    <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                      Used to protect forms from spam.
                    </span>
                  </td>
                </tr>
                <tr
                  style={{
                    fontFamily: "Roboto, Arial",
                    fontSize: 12,
                    lineHeight: "1.67",
                    margin: "0 0 8px",
                    verticalAlign: "top"
                  }}
                >
                  <td
                    style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}
                  >
                    Provider:
                  </td>
                  <td style={{ display: "inline-block", marginLeft: 5 }}>
                    <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                      c.clarity.ms
                    </span>
                  </td>
                </tr>
                <tr
                  style={{
                    fontFamily: "Roboto, Arial",
                    fontSize: 12,
                    lineHeight: "1.67",
                    margin: "0 0 8px",
                    verticalAlign: "top"
                  }}
                >
                  <td
                    style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}
                  >
                    Service:
                  </td>
                  <td style={{ display: "inline-block", marginLeft: 5 }}>
                    <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                      www.google.com{" "}
                    </span>
                  </td>
                </tr>
                <tr
                  style={{
                    fontFamily: "Roboto, Arial",
                    fontSize: 12,
                    lineHeight: "1.67",
                    margin: "0 0 8px",
                    verticalAlign: "top"
                  }}
                >
                  <td
                    style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}
                  >
                    Country:
                  </td>
                  <td style={{ display: "inline-block", marginLeft: 5 }}>
                    <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                      United States
                    </span>
                  </td>
                </tr>
                <tr
                  style={{
                    fontFamily: "Roboto, Arial",
                    fontSize: 12,
                    lineHeight: "1.67",
                    margin: "0 0 8px",
                    verticalAlign: "top"
                  }}
                >
                  <td
                    style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}
                  >
                    Type:
                  </td>
                  <td style={{ display: "inline-block", marginLeft: 5 }}>
                    <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                      server_cookie
                    </span>
                  </td>
                </tr>
                <tr
                  style={{
                    fontFamily: "Roboto, Arial",
                    fontSize: 12,
                    lineHeight: "1.67",
                    margin: "0 0 8px",
                    verticalAlign: "top"
                  }}
                >
                  <td
                    style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}
                  >
                    Expires in:
                  </td>
                  <td style={{ display: "inline-block", marginLeft: 5 }}>
                    <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                      session
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
      <div />
      <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ fontSize: 15 }}>
            <strong>
              <u>
                <br />
                Performance and functionality cookies:
              </u>
            </strong>
          </span>
        </span>
      </div>
      <div>
        <p style={{ fontSize: 15, lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              These cookies are used to enhance the performance and functionality of
              our Website but are non-essential to their use. However, without these
              cookies, certain functionality (like videos) may become unavailable.
            </span>
          </span>
        </p>
        <div>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <section
                data-custom-class="body_text"
                style={{
                  width: "100%",
                  border: "1px solid #e6e6e6",
                  margin: "0 0 10px",
                  borderRadius: 3
                }}
              >
                <div
                  style={{ padding: "8px 13px", borderBottom: "1px solid #e6e6e6" }}
                >
                  <table>
                    <tbody>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Name:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            MR
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: 0,
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Purpose:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            This cookie is used by Microsoft to reset or refresh the
                            MUID cookie.
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Provider:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            .c.clarity.ms
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Service:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            Microsoft{" "}
                            <a
                              href="https://privacy.microsoft.com/"
                              style={{ color: "#003AFA !important" }}
                              rel="noreferrer"
                              target="_blank"
                            >
                              View Service Privacy Policy
                            </a>{" "}
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Country:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            United States
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Type:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            server_cookie
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Expires in:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            7 days
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
              <section
                data-custom-class="body_text"
                style={{
                  width: "100%",
                  border: "1px solid #e6e6e6",
                  margin: "0 0 10px",
                  borderRadius: 3
                }}
              >
                <div
                  style={{ padding: "8px 13px", borderBottom: "1px solid #e6e6e6" }}
                >
                  <table>
                    <tbody>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Name:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            MR
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: 0,
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Purpose:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            This cookie is used by Microsoft to reset or refresh the
                            MUID cookie.
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Provider:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            .c.bing.com
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Service:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            Microsoft{" "}
                            <a
                              href="https://privacy.microsoft.com/"
                              rel="noreferrer"
                              style={{ color: "#003AFA !important" }}
                              target="_blank"
                            >
                              View Service Privacy Policy
                            </a>{" "}
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Country:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            United States
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Type:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            server_cookie
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Expires in:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            7 days
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
              <section
                data-custom-class="body_text"
                style={{
                  width: "100%",
                  border: "1px solid #e6e6e6",
                  margin: "0 0 10px",
                  borderRadius: 3
                }}
              >
                <div
                  style={{ padding: "8px 13px", borderBottom: "1px solid #e6e6e6" }}
                >
                  <table>
                    <tbody>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Name:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            SM
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: 0,
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Purpose:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            Session cookie used to collect anonymous information on
                            how visitors use a site to help improve their experience
                            and for better target ads.
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Provider:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            .c.clarity.ms
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Service:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            ChanelAdvisor{" "}
                            <a
                              href="https://www.channeladvisor.com/privacy-policy/"
                              rel="noreferrer"
                              style={{ color: "#003AFA !important" }}
                              target="_blank"
                            >
                              View Service Privacy Policy
                            </a>{" "}
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Country:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            United States
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Type:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            server_cookie
                          </span>
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontFamily: "Roboto, Arial",
                          fontSize: 12,
                          lineHeight: "1.67",
                          margin: "0 0 8px",
                          verticalAlign: "top"
                        }}
                      >
                        <td
                          style={{
                            textAlign: "right",
                            color: "#19243c",
                            minWidth: 80
                          }}
                        >
                          Expires in:
                        </td>
                        <td style={{ display: "inline-block", marginLeft: 5 }}>
                          <span
                            style={{ color: "#8b93a7", wordBreak: "break-all" }}
                          >
                            session
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </span>
          </span>
        </div>
      </div>
      <div>
        <div style={{ lineHeight: "1.5" }}>
          <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
            <span style={{ fontSize: 15 }}>
              <strong>
                <u>
                  <br />
                  Analytics and customization cookies:
                </u>
              </strong>
            </span>
          </span>
        </div>
        <p style={{ fontSize: 15, lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                These cookies collect information that is used either in aggregate
                form to help us understand how our Website is being used or how
                effective our marketing campaigns are, or to help us customize our
                Website for you.
              </span>
            </span>
          </span>
        </p>
        <div>
          <span data-custom-class="body_text">
            <section
              data-custom-class="body_text"
              style={{
                width: "100%",
                border: "1px solid #e6e6e6",
                margin: "0 0 10px",
                borderRadius: 3
              }}
            >
              <div
                style={{ padding: "8px 13px", borderBottom: "1px solid #e6e6e6" }}
              >
                <table>
                  <tbody>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Name:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          MUID
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: 0,
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Purpose:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          Sets a unique user id for tracking how the user uses the
                          site. Persistent cookie that is saved for 3 years
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Provider:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          .clarity.ms
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Service:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          Bing Analytics{" "}
                          <a
                            href="https://privacy.microsoft.com/"
                            rel="noreferrer"
                            style={{ color: "#003AFA !important" }}
                            target="_blank"
                          >
                            View Service Privacy Policy
                          </a>{" "}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Country:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          United States
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Type:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          http_cookie
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Expires in:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          1 year 24 days
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section
              data-custom-class="body_text"
              style={{
                width: "100%",
                border: "1px solid #e6e6e6",
                margin: "0 0 10px",
                borderRadius: 3
              }}
            >
              <div
                style={{ padding: "8px 13px", borderBottom: "1px solid #e6e6e6" }}
              >
                <table>
                  <tbody>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Name:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          MUID
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: 0,
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Purpose:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          Sets a unique user id for tracking how the user uses the
                          site. Persistent cookie that is saved for 3 years
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Provider:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          .bing.com
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Service:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          Bing Analytics{" "}
                          <a
                            href="https://privacy.microsoft.com/"
                            rel="noreferrer"
                            style={{ color: "#003AFA !important" }}
                            target="_blank"
                          >
                            View Service Privacy Policy
                          </a>{" "}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Country:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          United States
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Type:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          http_cookie
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Expires in:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          1 year 24 days
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section
              data-custom-class="body_text"
              style={{
                width: "100%",
                border: "1px solid #e6e6e6",
                margin: "0 0 10px",
                borderRadius: 3
              }}
            >
              <div
                style={{ padding: "8px 13px", borderBottom: "1px solid #e6e6e6" }}
              >
                <table>
                  <tbody>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Name:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          c.gif
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: 0,
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Purpose:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          __________
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Provider:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          c.clarity.ms
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Service:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          __________{" "}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Country:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          United States
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Type:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          pixel_tracker
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Expires in:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          session
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </span>
        </div>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ fontSize: 15 }}>
            <strong>
              <u>
                <br />
                Advertising cookies:
              </u>
            </strong>
          </span>
        </span>
      </div>
      <div>
        <p style={{ fontSize: 15, lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                These cookies are used to make advertising messages more relevant to
                you. They perform functions like preventing the same ad from
                continuously reappearing, ensuring that ads are properly displayed
                for advertisers, and in some cases selecting advertisements that are
                based on your interests.
              </span>
            </span>
          </span>
        </p>
        <div>
          <span data-custom-class="body_text">
            <section
              data-custom-class="body_text"
              style={{
                width: "100%",
                border: "1px solid #e6e6e6",
                margin: "0 0 10px",
                borderRadius: 3
              }}
            >
              <div
                style={{ padding: "8px 13px", borderBottom: "1px solid #e6e6e6" }}
              >
                <table>
                  <tbody>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Name:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          SRM_B
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: 0,
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Purpose:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          Atlast Adserver used in conjunction with Bing services.
                          Expires after 180 days
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Provider:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          .c.bing.com
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Service:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          Atlas{" "}
                          <a
                            href="https://www.facebook.com/privacy/policy/"
                            rel="noreferrer"
                            style={{ color: "#003AFA !important" }}
                            target="_blank"
                          >
                            View Service Privacy Policy
                          </a>{" "}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Country:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          United States
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Type:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          server_cookie
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Expires in:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          1 year 24 days
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section
              data-custom-class="body_text"
              style={{
                width: "100%",
                border: "1px solid #e6e6e6",
                margin: "0 0 10px",
                borderRadius: 3
              }}
            >
              <div
                style={{ padding: "8px 13px", borderBottom: "1px solid #e6e6e6" }}
              >
                <table>
                  <tbody>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Name:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          ANONCHK
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: 0,
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Purpose:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          Used by Bing as a unique user identifier for users seeing
                          bing ads
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Provider:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          .c.clarity.ms
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Service:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          Bing{" "}
                          <a
                            href="https://privacy.microsoft.com/"
                            rel="noreferrer"
                            style={{ color: "#003AFA !important" }}
                            target="_blank"
                          >
                            View Service Privacy Policy
                          </a>{" "}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Country:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          United States
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Type:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          server_cookie
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Expires in:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          10 minutes
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </span>
        </div>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ fontSize: 15 }}>
            <strong>
              <u>
                <br />
                Unclassified cookies:
              </u>
            </strong>
          </span>
        </span>
      </div>
      <div>
        <p style={{ fontSize: 15, lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                These are cookies that have not yet been categorized. We are in the
                process of classifying these cookies with the help of their
                providers.
              </span>
            </span>
          </span>
        </p>
        <div>
          <span data-custom-class="body_text">
            <section
              data-custom-class="body_text"
              style={{
                width: "100%",
                border: "1px solid #e6e6e6",
                margin: "0 0 10px",
                borderRadius: 3
              }}
            >
              <div
                style={{ padding: "8px 13px", borderBottom: "1px solid #e6e6e6" }}
              >
                <table>
                  <tbody>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Name:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          _clsk
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: 0,
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Purpose:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          __________
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Provider:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          .skippr.app
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Service:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          __________{" "}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Country:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          United States
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Type:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          http_cookie
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Expires in:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          1 day
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section
              data-custom-class="body_text"
              style={{
                width: "100%",
                border: "1px solid #e6e6e6",
                margin: "0 0 10px",
                borderRadius: 3
              }}
            >
              <div style={{ padding: "8px 13px", borderBottom: "1px solid #e6e6e6" }}>
                <table>
                  <tbody>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}>
                        Name:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          _ga
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: 0,
                        verticalAlign: "top"
                      }}
                    >
                      <td style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}>
                        Purpose:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          Records a particular ID used to come up with data about website
                          usage by the user
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}>
                        Provider:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          .skippr.app
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}>
                        Service:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          Google Analytics{" "}
                          <a
                            rel="noreferrer"
                            href="https://policies.google.com/privacy"
                            style={{ color: "#003AFA !important" }}
                            target="_blank"
                          >
                            View Service Privacy Policy
                          </a>
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}>
                        Country:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          United States
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}>
                        Type:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          http_cookie
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}>
                        Expires in:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          1 year 1 month 4 days
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section
              data-custom-class="body_text"
              style={{
                width: "100%",
                border: "1px solid #e6e6e6",
                margin: "0 0 10px",
                borderRadius: 3
              }}
            >
              <div style={{ padding: "8px 13px", borderBottom: "1px solid #e6e6e6" }}>
                <table>
                  <tbody>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}>
                        Name:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          _ga_#
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: 0,
                        verticalAlign: "top"
                      }}
                    >
                      <td style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}>
                        Purpose:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          Used to distinguish individual users by means of designation of a
                          randomly generated number as client identifier, which allows
                          calculation of visits and sessions
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}>
                        Provider:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          .skippr.app
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}>
                        Service:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          Google Analytics{" "}
                          <a
                            rel="noreferrer"
                            href="https://policies.google.com/privacy"
                            style={{ color: "#003AFA !important" }}
                            target="_blank"
                          >
                            View Service Privacy Policy
                          </a>{" "}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}>
                        Country:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          United States
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}>
                        Type:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          http_cookie
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td style={{ textAlign: "right", color: "#19243c", minWidth: 80 }}>
                        Expires in:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          1 year 1 month 4 days
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section
              data-custom-class="body_text"
              style={{
                width: "100%",
                border: "1px solid #e6e6e6",
                margin: "0 0 10px",
                borderRadius: 3
              }}
            >
              <div
                style={{ padding: "8px 13px", borderBottom: "1px solid #e6e6e6" }}
              >
                <table>
                  <tbody>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Name:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          CLID
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: 0,
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Purpose:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          __________
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Provider:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          www.clarity.ms
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Service:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          __________{" "}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Country:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          United States
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Type:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          server_cookie
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Expires in:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          11 months 30 days
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section
              data-custom-class="body_text"
              style={{
                width: "100%",
                border: "1px solid #e6e6e6",
                margin: "0 0 10px",
                borderRadius: 3
              }}
            >
              <div
                style={{ padding: "8px 13px", borderBottom: "1px solid #e6e6e6" }}
              >
                <table>
                  <tbody>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Name:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          _cltk
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: 0,
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Purpose:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          __________
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Provider:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          beta.skippr.app
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Service:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          __________{" "}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Country:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          United States
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Type:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          html_session_storage
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Expires in:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          session
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section
              data-custom-class="body_text"
              style={{
                width: "100%",
                border: "1px solid #e6e6e6",
                margin: "0 0 10px",
                borderRadius: 3
              }}
            >
              <div
                style={{ padding: "8px 13px", borderBottom: "1px solid #e6e6e6" }}
              >
                <table>
                  <tbody>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Name:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          _clck
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: 0,
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Purpose:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          __________
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Provider:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          .skippr.app
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Service:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          __________{" "}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Country:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          United States
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Type:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          http_cookie
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: "1.67",
                        margin: "0 0 8px",
                        verticalAlign: "top"
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80
                        }}
                      >
                        Expires in:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span style={{ color: "#8b93a7", wordBreak: "break-all" }}>
                          11 months 30 days
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </span>
        </div>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
            <strong>
              <span data-custom-class="heading_1">
                How can I control cookies on my browser?
              </span>
            </strong>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="body_text">
          As the means by which you can refuse cookies through your web browser
          controls vary from browser to browser, you should visit your browser&apos;s
          help menu for more information. The following is information about how to
          manage cookies on the most popular browsers:
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a
              href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span data-custom-class="link">Chrome</span>
            </a>
          </span>
        </li>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a
              href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span data-custom-class="link">Internet Explorer</span>
            </a>
          </span>
        </li>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a
              href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span data-custom-class="link">Firefox</span>
            </a>
          </span>
        </li>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a
              href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span data-custom-class="link">Safari</span>
            </a>
          </span>
        </li>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a
              href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span data-custom-class="link">Edge</span>
            </a>
          </span>
        </li>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a
              href="https://help.opera.com/en/latest/web-preferences/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span data-custom-class="link">Opera</span>
            </a>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="body_text">
          In addition, most advertising networks offer you a way to opt out of
          targeted advertising. If you would like to find out more information,
          please visit:
        </span>
      </div>
      <ul>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a
              href="http://www.aboutads.info/choices/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span data-custom-class="link">Digital Advertising Alliance</span>
            </a>
          </span>
        </li>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a
              href="https://youradchoices.ca/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span data-custom-class="link">
                Digital Advertising Alliance of Canada
              </span>
            </a>
          </span>
        </li>
        <li style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a
              href="http://www.youronlinechoices.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span data-custom-class="body_text">
                <span data-custom-class="link">
                  European Interactive Digital Advertising Alliance
                </span>
              </span>
            </a>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
            <strong>
              <span data-custom-class="heading_1">
                What about other tracking technologies, like web beacons?
              </span>
            </strong>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              Cookies are not the only way&nbsp;
            </span>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                to recognize or track visitors to a website. We may use other,
                similar technologies from time to time, like web beacons (sometimes
                called &quot;tracking pixels&quot; or &quot;clear gifs&quot;). These are tiny graphics
                files that contain a unique identifier that enables us to recognize
                when someone has visited our Website
                <span className="block-component" /> or opened an email including
                them<span className="statement-end-if-in-editor"></span>. This allows
                us, for example, to monitor&nbsp;
              </span>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    the traffic patterns of users from one page within a website to
                    another, to deliver or communicate with cookies, to understand
                    whether you have come to the website from an online
                    advertisement displayed on a third-party website, to improve
                    site performance, and to measure the success of email marketing
                    campaigns. In many instances, these technologies are reliant on
                    cookies to function properly, and so declining cookies will
                    impair their functioning.
                  </span>
                  <span className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      Do you use Flash cookies or Local Shared Objects?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            Websites may also use so-called &quot;Flash Cookies&quot; (also known as Local
            Shared Objects or &quot;LSOs&quot;) to, among other things, collect and store
            information about your use of our services, fraud prevention, and for
            other site operations.
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            If you do not want Flash Cookies stored on your computer, you can adjust
            the settings of your Flash player to block Flash Cookies storage using
            the tools contained in the&nbsp;
          </span>
        </span>
        <span data-custom-class="body_text">
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a
              data-custom-class="link"
              rel="noreferrer"
              href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
              target="_BLANK"
            >
              <span style={{ fontSize: 15 }}>Website Storage Settings Panel</span>
            </a>
          </span>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            . You can also control Flash Cookies by going to the&nbsp;
          </span>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a
              data-custom-class="link"
              rel="noreferrer"
              href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
              target="_BLANK"
            >
              <span style={{ fontSize: 15 }}>Global Storage Settings Panel</span>
            </a>
          </span>
        </span>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">&nbsp;and&nbsp;</span>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              following the instructions (which may include instructions that
              explain, for example, how to delete existing Flash Cookies (referred
              to &quot;information&quot; on the Macromedia site), how to prevent Flash LSOs
              from being placed on your computer without your being asked, and (for
              Flash Player 8 and later) how to block Flash Cookies that are not
              being delivered by the operator of the page you are on at the time).
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              Please note that setting the Flash Player to restrict or limit
              acceptance of Flash Cookies may reduce or impede the functionality of
              some Flash applications, including, potentially, Flash applications
              used in connection with our services or online content.
            </span>
          </span>
        </span>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span className="statement-end-if-in-editor" />
                  <span className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                      <strong>
                        <span data-custom-class="heading_1">
                          Do you serve targeted advertising?
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            Third parties may serve cookies on your computer or mobile device to
            serve advertising through our Website. These companies may use
            information about your visits to this and other websites in order to
            provide relevant advertisements about goods and services that you may be
            interested in. They may also employ technology that is used to measure
            the effectiveness of advertisements. They can accomplish this by using
            cookies or web beacons to collect information about your visits to this
            and other sites in order to provide relevant advertisements about goods
            and services of potential interest to you. The information collected
            through this process does not enable us or them to identify your name,
            contact details, or other details that directly identify you unless you
            choose to provide these.
          </span>
        </span>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span className="statement-end-if-in-editor" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
            <strong>
              <span data-custom-class="heading_1">
                How often will you update this Cookie Policy?
              </span>
            </strong>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">We may update&nbsp;</span>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                this Cookie Policy from time to time in order to reflect, for
                example, changes to the cookies we use or for other operational,
                legal, or regulatory reasons. Please therefore revisit this Cookie
                Policy regularly to stay informed about our use of cookies and
                related technologies.
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                The date at the top of this Cookie Policy indicates when it was last
                updated.
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
              <strong>
                <span data-custom-class="heading_1">
                  Where can I get further information?
                </span>
              </strong>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                If you have any questions about our use of cookies or other
                technologies, please email us at{" "}
                <span className="question">privacy@skippr.com</span> or by post to:
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="body_text">
          <br />
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span className="question">Skippr Ltd.</span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span className="question">
            <span data-custom-class="body_text">
              153 Milton Keynes Business Centre
            </span>
          </span>
          <span data-custom-class="body_text">
            <span className="block-component" />
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="body_text">
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span className="question">Milton Keynes</span>
            <span className="block-component" />,{" "}
            <span className="question">Buckinghamshire</span>
            <span className="statement-end-if-in-editor" />
            <span className="block-component" />
            <span className="question">MK14 6GD</span>
            <span className="statement-end-if-in-editor" />
            <span className="block-component" />
            <span className="block-component" />
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="body_text">
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span className="question">United Kingdom</span>
            <span className="statement-end-if-in-editor" />
          </span>
        </span>
        <span data-custom-class="body_text">
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span className="statement-end-if-in-editor">
              <span className="block-component" />
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="body_text">
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span className="statement-end-if-in-editor">
              Phone: <span className="question">(+44)07983013442</span>
            </span>
          </span>
        </span>
        <span data-custom-class="body_text">
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span className="block-component" />
          </span>
        </span>
      </div>
    </>
  )
}